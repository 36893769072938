import { getGlobals } from 'src/globals/globals';
import { useEffect, useState } from 'react';
import { LpLeaderboard } from '@livepolls/ui-components/src/components/leaderboard/LpLeaderboard';
import { LpPanelContainer } from '@livepolls/ui-components/src/components/panel/LpPanelContainer';
import { LpPanelHeading } from '@livepolls/ui-components/src/components/panel/LpPanelHeading';
import { DashboardPanelType } from '@livepolls/ui-components/src/enums/dashboard-panel-type.enum';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import styles from './DashboardReports.module.css';
import { LpResponseCountAndTime } from '@livepolls/ui-components/src/components/response-count-and-time/LpResponseCountAndTime';
import { LivePollQuestionReports } from './LivePollQuestionReports';
import { LpProfileQuestionReports } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpProfileQuestionReports';
import {
  transformLeaderboardToPager,
  transformTeamLeaderboardToPager,
} from '@livepolls/ui-components/src/utils/generate-leaderboard-row';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { getApi } from 'src/utils/api.util';
import { DashboardReportsForMobile } from './DashboardReportsForMobile';
import { TABLET_SCREEN_BREAKPOINT } from '@livepolls/ui-components/src/constants/device-breakpoints.contants';
import { useScreenResize } from '@livepolls/ui-components/src/utils/getScreenSize.util';

interface Props {
  dashboardReportUid: string;
}

export const DashboardReports = ({ dashboardReportUid }: Props) => {
  const REPORT_URL = getGlobals()!.REPORT_URL;
  const [panelType, setPanelType] = useState<DashboardPanelType>(
    DashboardPanelType.LIVEPOLL_QUESTIONS,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { width } = useScreenResize();
  const [reportPayload, setReportPayload] = useState<{
    livePoll: { id: number; type: LivePollType };
    livePollSession: ILivePollSession;
    livePollSessionReport: ILivePollSessionReport;
  }>();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { livePoll, livePollSession, livePollSessionReport } =
          await getApi(
            `/take/sharable-report/livepoll-session/report/${dashboardReportUid}`,
          );
        setReportPayload({
          livePoll,
          livePollSession,
          livePollSessionReport,
        });
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dashboardReportUid]);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <LpSpinner message="Loading Session Report..." />
      </div>
    );
  }

  if (error) {
    return <div>Oops an error occurred ({error?.message}).</div>;
  }

  if (!reportPayload?.livePoll) {
    return <div>Oops an error occurred (LivePoll not found).</div>;
  }

  if (!reportPayload.livePollSession) {
    return <div>Oops an error occurred (LivePollSession not found).</div>;
  }

  if (!reportPayload.livePollSessionReport) {
    return <div>Oops an error occurred (LivePollSessionReport not found).</div>;
  }

  const { livePoll, livePollSession, livePollSessionReport } = reportPayload;
  const isQuiz = livePoll.type === LivePollType.QUIZ;
  const {
    responseCount,
    questionReports,
    leaderboardRows,
    profileQuestionReports,
    teamLeaderboardRows,
  } = livePollSessionReport;

  const displayRightWidget = isQuiz;
  const handelPanelChange = (panelType: DashboardPanelType) => {
    setPanelType(panelType);
  };
  const isProfileQuestionsActive =
    panelType === DashboardPanelType.PROFILE_QUESTIONS;
  const isLivePollQuestionsActive =
    panelType === DashboardPanelType.LIVEPOLL_QUESTIONS;

  const showDifficultyLevels =
    !!livePollSession.setting?.displayDifficultyLevels;
  const enableQuestionDifficulty =
    !!livePollSession.setting?.enableQuestionDifficulty;
  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;
  const teams = livePollSession.setting?.teamSetting?.teams || [];

  const isTabletView = width <= TABLET_SCREEN_BREAKPOINT;

  if (isTabletView) {
    return (
      <DashboardReportsForMobile
        livePollSession={livePollSession}
        livePoll={livePoll}
        livePollSessionReport={livePollSessionReport}
        reportUrl={getReportUrl(
          REPORT_URL,
          livePollSession.sharableReportDashboardUid,
        )}
        teams={teams}
      />
    );
  }

  return (
    <>
      <div className={styles.left}>
        <div className={styles.leftWrapper}>
          <div
            className={
              isQuiz
                ? styles.questionsContainer
                : styles.questionsContainerForPoll
            }
          >
            <LpResponseCountAndTime
              livePollSession={livePollSession}
              responseCount={responseCount}
              reportUrl={getReportUrl(
                REPORT_URL,
                livePollSession.sharableReportDashboardUid,
              )}
              questionCount={questionReports.length}
              text={{
                quiz: 'Quiz',
                poll: 'Poll',
                duration: 'Duration',
                question: 'Question',
                questions: 'Questions',
                participant: 'Participant',
                participants: 'Participants',
              }}
            />
            <div className={styles.mainWrapper}>
              <div className={styles.innerContainer}>
                {!livePollSession.setting?.enableProfileQuestions && (
                  <div className={styles.responseSummaryText}>
                    Response summary
                  </div>
                )}
                {livePollSession.setting?.enableProfileQuestions && (
                  <LpPanelContainer
                    customContainerClass={styles.panelContainer}
                  >
                    <LpPanelHeading
                      onPanelHeadClick={() =>
                        handelPanelChange(DashboardPanelType.LIVEPOLL_QUESTIONS)
                      }
                      isActive={isLivePollQuestionsActive}
                    >
                      <span className={styles.panelText}>Response summary</span>
                    </LpPanelHeading>
                    <LpPanelHeading
                      onPanelHeadClick={() =>
                        handelPanelChange(DashboardPanelType.PROFILE_QUESTIONS)
                      }
                      isActive={isProfileQuestionsActive}
                    >
                      <span className={styles.panelText}>
                        Profile Questions
                      </span>
                    </LpPanelHeading>
                  </LpPanelContainer>
                )}
                {isLivePollQuestionsActive && (
                  <LivePollQuestionReports
                    livePollId={livePollSession.livePollId}
                    livePollSessionId={livePollSession.id}
                    isQuiz={isQuiz}
                    responseCount={responseCount}
                    questionReports={questionReports}
                    profileQuestionReport={profileQuestionReports}
                    showDifficultyLevels={showDifficultyLevels}
                    enableQuestionDifficulty={enableQuestionDifficulty}
                    teamLeaderboardRows={teamLeaderboardRows}
                    teams={teams}
                  />
                )}
                {isProfileQuestionsActive && (
                  <LpProfileQuestionReports
                    questionReports={profileQuestionReports!}
                    responseCount={responseCount}
                    text={{
                      noVotes: 'No votes',
                      outOf: 'out of',
                      votes: 'votes',
                      initializing: 'Initializing',
                      noDataAvailable: 'No Data Available',
                      sortBy: 'Sort by',
                      default: 'Default',
                      popularity: 'Popularity',
                      accuracy: 'Accuracy',
                      reverseOrder: 'Reverse order',
                      profileQuestion: 'Profile question',
                      teamRankings: 'Team rankings',
                      individualView: 'Individual view',
                      teamsView: 'Teams view',
                    }}
                  />
                )}
              </div>
              {displayRightWidget && (
                <div className={styles.dashboardReport}>
                  <LpLeaderboard
                    showSequenceNum={true}
                    pagewiseResults={transformLeaderboardToPager(
                      leaderboardRows,
                      {
                        leaderboard: 'Leaderboard',
                      },
                    )}
                    text={{ noDataAvailable: 'No Data Available' }}
                    teamResults={transformTeamLeaderboardToPager(
                      teamLeaderboardRows || [],
                    )}
                    isTeamsEnabled={isTeamsEnabled}
                    isResultScreeen={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getReportUrl = (reportUrl: string, reportUid?: string) => {
  if (reportUid != null) {
    return `${reportUrl}/${reportUid}`;
  }
};
