import { CSSProperties } from 'react';
import { QuestionDifficultyLevel } from 'src/enums/question-difficulty-level.enum';
import { IMultipleChoiceQuestionReport } from '../../../interfaces/multiple-choice-question-report';
import { TeamLeaderboardRow } from '../../../interfaces/team-leaderboard-row';
import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';
import { LpQuestionResultChartForChoice } from '../../../components/question-result-chart/choice/LpQuestionResultChartForChoice';
import { LpTopThreeLeaderboard } from './LpTopThreeLeaderboard';
import { MultiChoiceQuestionIcon } from '../../../components/svgImages/MultiChoiceQuestionIcon.svg';
import { ITeam } from '../../../interfaces/team.interface';
import styles from './LpMultipleChoiceReport.module.css';

interface Props {
  isQuiz: boolean;
  responseCount: number;
  questionReport: IMultipleChoiceQuestionReport;
  isPreviewScreen?: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
    topScorers: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    sortBy: string;
    multipleChoice: string;
    teamRankings: string;
    teamsView: string;
    individualView: string;
  };
  showDifficultyLevels?: boolean;
  difficultyLevel?: QuestionDifficultyLevel;
  difficultyLevelIndicatorStyle?: CSSProperties;
  teams: ITeam[];
}

export const LpMultipleChoiceReport = ({
  isQuiz,
  questionReport,
  teamLeaderboardRows,
  text,
  showDifficultyLevels,
  difficultyLevel,
  difficultyLevelIndicatorStyle,
  teams,
}: Props) => {
  const { answerStats, responsesByTeam } = questionReport;

  const showQuestionResultChartForTeams =
    responsesByTeam && teamLeaderboardRows && teamLeaderboardRows?.length > 0;

  const showMultipleChoiceQuestionLeaderboard =
    !showQuestionResultChartForTeams && isQuiz;

  const topThreeLeaderboardArray =
    questionReport?.leaderboard
      ?.filter(item => item.score !== 0) // Filters out items with a score of 0
      .map(item => {
        return { name: item.respondentName, value: item.score.toString() };
      }) || [];

  return (
    <div className={styles.chartRow} data-testid="multiple choice report">
      <LpQuestionTitle
        currentQuestionNumber={questionReport.currentQuestionNumber || 0}
        showDifficultyLevels={showDifficultyLevels}
        difficultyLevel={difficultyLevel}
        difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        title={questionReport.title}
        image={questionReport.image}
        type={text.multipleChoice}
        icon={<MultiChoiceQuestionIcon />}
      />
      {showMultipleChoiceQuestionLeaderboard && (
        <LpTopThreeLeaderboard
          items={topThreeLeaderboardArray}
          title={text.topScorers}
          testIdForName="topItemForChoiceQuestion"
          testIdForScore="topItemScoreForChoiceQuestion"
        />
      )}

      <div className={styles.resultContainer}>
        <div className={styles.chartRowInner}>
          <LpQuestionResultChartForChoice
            isQuiz={isQuiz}
            answerStats={answerStats}
            text={text}
            showTeamsChart={!!showQuestionResultChartForTeams}
            responsesByTeam={responsesByTeam}
            teams={teams}
          />
        </div>
      </div>
    </div>
  );
};
