export const WordCloudIconSixteenPxSvg = () => {
  const image = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 64 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9138 43.5H12.3733C5.53973 43.5 0 37.9995 0 31.2143C0 25.357 4.12819 20.457 9.65325 19.2264C9.40902 18.1427 9.28 17.0149 9.28 15.8571C9.28 7.37563 16.2047 0.5 24.7467 0.5C32.2293 0.5 38.4709 5.77599 39.904 12.7857L48.5333 12.7857C57.0753 12.7857 64 19.6613 64 28.1429C64 36.6244 57.0753 43.5 48.5333 43.5H41.0462H23.9138ZM13 29.5H53V33.5H13V29.5ZM37 21.5H13V25.5H37V21.5Z"
        fill="currentColor"
      />
    </svg>
  );
  return image;
};
