import {
  IconDefinition,
  faMedal,
  faStar,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ToggleSwitchSideEnum } from '../../enums/toggle-switch-side.enum';
import { LpImageToogle } from '../image-toggle/LpImageToggle';
import { RibbonSvg } from '../svgImages/Ribbon.svg';
import userGroupWhite from '../../images/user-group-white.svg';
import userGroup from '../../images/user-group.svg';
import { ordinalSuffix } from '../../utils/ordinal-suffix.util';
import styles from './LpLeaderboard.module.css';

interface Result {
  text: string;
  count: number;
  percentage?: number;
  rank: number;
  color?: string;
}

interface PageResult {
  title: string;
  results: Result[];
  blueColor?: boolean;
  icon: IconDefinition;
}

interface Props {
  showSequenceNum?: boolean;
  pagewiseResults: PageResult[] | undefined;
  teamResults: Result[] | undefined;
  text: {
    noDataAvailable: string;
  };
  isTeamsEnabled: boolean;
  isResultScreeen?: boolean;
}

const defaultLeaderboard = {
  title: 'Leaderboard',
  results: [],
  icon: faMedal,
};

export const LpLeaderboard: React.FC<Props> = ({
  pagewiseResults,
  text,
  showSequenceNum,
  isTeamsEnabled,
  teamResults,
  isResultScreeen,
}) => {
  const [pointsOrAccuracyView, setPointsOrAccuracyView] =
    useState<ToggleSwitchSideEnum>(ToggleSwitchSideEnum.LEFT);
  const [individulaOrTeamsView, setIndividulaOrTeamsView] =
    useState<ToggleSwitchSideEnum>(ToggleSwitchSideEnum.LEFT);

  const handleChangePointOrAccuracyView = (newView: ToggleSwitchSideEnum) => {
    setPointsOrAccuracyView(newView);
  };

  const handleChangeIndividulaOrTeamsView = (
    individulaOrTeamsView: ToggleSwitchSideEnum,
  ) => {
    setIndividulaOrTeamsView(individulaOrTeamsView);
  };

  const renderHeader = (pr: PageResult) => (
    <div
      className={
        isResultScreeen
          ? styles.questionHeader
          : styles.questionHeaderForLiveSession
      }
    >
      <div className={styles.questionText}>{pr.title}</div>
    </div>
  );

  const renderTeamToggles = (
    <LpImageToogle
      onChangeView={handleChangeIndividulaOrTeamsView}
      leftTooltipText="Sort by individual view"
      rightTooltipText="Sort by teams"
      activeView={individulaOrTeamsView}
      leftSideIcon={<FontAwesomeIcon icon={faUser} />}
      rightSideIcon={<img src={userGroup} alt="teams inactive" />}
      leftSideActiveIcon={<FontAwesomeIcon icon={faUser} color="#f5f5f5" />}
      rightSideActiveIcon={<img src={userGroupWhite} alt="teams active" />}
    />
  );

  const renderToggles = (
    <LpImageToogle
      onChangeView={handleChangePointOrAccuracyView}
      leftTooltipText="Sort by points"
      rightTooltipText="Sort by accuracy"
      activeView={pointsOrAccuracyView}
      leftSideIcon={
        <FontAwesomeIcon icon={faStar} aria-label="sort by point" />
      }
      rightSideIcon={
        <span className="wi-check" data-testid="sortByAccuracy"></span>
      }
      leftSideActiveIcon={<FontAwesomeIcon icon={faStar} color="#f5f5f5" />}
      rightSideActiveIcon={
        <span className={`wi-check ${styles.checkIcon}`}></span>
      }
    />
  );

  const isPointsView = pointsOrAccuracyView === ToggleSwitchSideEnum.LEFT;
  const isAccuracyView = pointsOrAccuracyView === ToggleSwitchSideEnum.RIGHT;
  const isIndividualView = individulaOrTeamsView === ToggleSwitchSideEnum.LEFT;
  const isTeamsView = individulaOrTeamsView === ToggleSwitchSideEnum.RIGHT;

  const renderPagewise = () => {
    return pagewiseResults!.map((pr, index) => {
      return (
        <div key={index} className={styles.itemContainer}>
          {renderHeader(pr)}
          <div
            className={`${
              isTeamsEnabled ? styles.teamEnabledToggles : styles.toggles
            }`}
          >
            {isTeamsEnabled && renderTeamToggles}
            {renderToggles}
          </div>
          <div className={styles.answerRowsContainer}>
            <div className={styles.answerRows}>
              <div
                className={styles.leaderboardTable}
                data-testid="leaderboard-table"
              >
                {isIndividualView && (
                  <div>
                    {pr.results.map((result, index) => (
                      <div
                        key={'respondent' + index}
                        data-testid="leaderboard-row"
                        className={`${styles.leaderboardRow} ${
                          index > 0 && styles.secondRow
                        } ${index === 0 && styles.boldLeaderBoardRow}`}
                      >
                        <div className={styles.leftSide}>
                          {result.rank === 1 && (
                            <span
                              data-testid="ribbonImg"
                              className={styles.ribbonImg}
                            >
                              <RibbonSvg />
                            </span>
                          )}

                          {showSequenceNum && (
                            <div
                              className={styles.sequenceNum}
                              aria-label="leaderboard sequence number"
                            >
                              {result.rank}
                            </div>
                          )}
                          <div
                            className={styles.name}
                            title={
                              result.text.length > 16 ? result.text : undefined
                            }
                            aria-label="leaderboard name"
                          >
                            {ellipsisIfLong(result.text)}
                          </div>
                        </div>

                        {isPointsView && (
                          <div
                            className={styles.count}
                            aria-label="leaderboard score count"
                          >
                            {result.count}
                          </div>
                        )}

                        {isAccuracyView && result.percentage != null && (
                          <div
                            aria-label="leaderboard accuracy percentage"
                            className={styles.accuracy}
                          >
                            <div className={styles.percent}>
                              {Math.round(result.percentage)}%
                            </div>
                            <div
                              className={
                                styles.progress +
                                ' ' +
                                (pr.blueColor ? styles.blue : '')
                              }
                            >
                              <div
                                className={styles.bar}
                                style={{ width: `${result.percentage}%` }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {isTeamsView && (
                  <div>
                    {teamResults!.map((result, index) => {
                      return (
                        <div
                          key={'respondent' + index}
                          data-testid="leaderboard-row"
                          className={`${styles.leaderboardRowTeam}  ${
                            index === 0 && styles.boldLeaderBoardRow
                          }`}
                        >
                          <div className={styles.leftSideForTeams}>
                            <div
                              className={styles.sequenceNumber}
                              aria-label="team leaderboard sequence number"
                            >
                              {ordinalSuffix(result.rank)}
                            </div>

                            <div
                              className={styles.colorBox}
                              style={{ backgroundColor: result.color }}
                            ></div>
                            <div
                              className={styles.name}
                              title={
                                result.text.length > 16
                                  ? result.text
                                  : undefined
                              }
                              aria-label="leaderboard name"
                            >
                              {ellipsisIfLong(result.text)}
                            </div>
                          </div>

                          {isPointsView && (
                            <div
                              className={styles.count}
                              aria-label="leaderboard score count"
                            >
                              {result.count}
                            </div>
                          )}

                          {isAccuracyView && result.percentage != null && (
                            <div
                              aria-label="leaderboard accuracy percentage"
                              className={styles.accuracy}
                            >
                              <div className={styles.percent}>
                                {Math.round(result.percentage)}%
                              </div>
                              <div
                                className={
                                  styles.progress +
                                  ' ' +
                                  (pr.blueColor ? styles.blue : '')
                                }
                              >
                                <div
                                  className={styles.bar}
                                  style={{ width: `${result.percentage}%` }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const ellipsisIfLong = (text: string) => {
    return text.length > 24 ? text.substr(0, 24) + '...' : text;
  };

  if (pagewiseResults === undefined) {
    return (
      <div className={styles.itemContainer}>
        {renderHeader(defaultLeaderboard)}
        <div className={styles.noDataAvailableMsg}>{text.noDataAvailable}</div>
      </div>
    );
  }

  return (
    <div className={styles.resultContainerWrapper}>{renderPagewise()}</div>
  );
};
