import styles from './app.module.css';
import { Route, Routes } from 'react-router-dom';
import { DcRelativeApp } from './dcRelativeApp';
import '@npm-questionpro/wick-ui-lib/dist/style.css';

function App() {
  return (
    <div className={styles.appContainer}>
      <Routes>
        <Route
          path="/:dc/dashboard/:dashboardReportUid"
          element={<DcRelativeApp />}
        />

        <Route path="*" element={<p>Invalid url</p>} />
      </Routes>
    </div>
  );
}

export default App;
