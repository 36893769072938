import { WuTooltip } from '@npm-questionpro/wick-ui-lib';
import { ToggleSwitchSideEnum } from '../../enums/toggle-switch-side.enum';
import styles from './LpImageToggle.module.css';

interface Props {
  onChangeView: (side: ToggleSwitchSideEnum) => void;
  activeView: ToggleSwitchSideEnum;
  leftTooltipText: string;
  rightTooltipText: string;
  leftSideIcon: any;
  leftSideActiveIcon: any;
  rightSideIcon: any;
  rightSideActiveIcon: any;
}

export const LpImageToogle = ({
  onChangeView,
  activeView,
  leftTooltipText,
  rightTooltipText,
  leftSideIcon,
  leftSideActiveIcon,
  rightSideIcon,
  rightSideActiveIcon,
}: Props) => {
  const isLeftViewActive = activeView === ToggleSwitchSideEnum.LEFT;

  return (
    <div className={styles.toggle}>
      <div className={isLeftViewActive ? styles.left : styles.right}>
        <WuTooltip
          content={isLeftViewActive ? leftTooltipText : rightTooltipText}
          position="bottom"
        >
          {isLeftViewActive ? leftSideActiveIcon : rightSideActiveIcon}
        </WuTooltip>
      </div>
      <WuTooltip content={leftTooltipText} position="bottom">
        <div
          onClick={() => onChangeView(ToggleSwitchSideEnum.LEFT)}
          className={styles.icon}
        >
          {leftSideIcon}
        </div>
      </WuTooltip>

      <WuTooltip content={rightTooltipText} position="bottom">
        <div
          onClick={() => onChangeView(ToggleSwitchSideEnum.RIGHT)}
          className={styles.icon}
        >
          {rightSideIcon}
        </div>
      </WuTooltip>
    </div>
  );
};
