import { IWordCloudQuestionReport } from '../../../interfaces/word-cloud-report.interface';
import { LpWordCloudImage } from '../../word-cloud/LpWordCloud';
import { formatWordCloudResults } from '../../../utils/format-word-cloud-results.util';
import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';
import React from 'react';
import { WordCloudIconSixteenPxSvg } from '../../../components/svgImages/WordCloudIconSixteen.svg';
import styles from './LpWordCloudReport.module.css';

interface Props {
  wordCloudQuestionReport: IWordCloudQuestionReport;
  text: {
    mentions: string;
    noDataAvailable: string;
    popularMentions: string;
    wordCloudText: string;
  };
}

export const LpWordCloudReport = React.memo(
  ({ wordCloudQuestionReport, text }: Props) => {
    const results = formatWordCloudResults(
      wordCloudQuestionReport.wordCloudAnswers,
    );

    return (
      <div className={styles.container}>
        <LpQuestionTitle
          currentQuestionNumber={
            wordCloudQuestionReport.currentQuestionNumber || 0
          }
          title={wordCloudQuestionReport.title || ''}
          type={text.wordCloudText}
          icon={<WordCloudIconSixteenPxSvg />}
        />
        <div className={styles.innerContainer}>
          {results.length === 0 ? (
            <div className={styles.noDataAvailable}>No responses </div>
          ) : (
            <>
              <LpWordCloudImage
                wordCloudQuestionResponses={results}
                text={{
                  mentions: text.mentions,
                  popularMentions: text.popularMentions,
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);
