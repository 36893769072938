export const MultiChoiceQuestionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3C6 4.65685 4.65685 6 3 6ZM3 15C1.34315 15 0 13.6569 0 12C0 10.3431 1.34315 9 3 9C4.65685 9 6 10.3431 6 12C6 13.6569 4.65685 15 3 15ZM3 24C1.34315 24 0 22.6569 0 21C0 19.3431 1.34315 18 3 18C4.65685 18 6 19.3431 6 21C6 22.6569 4.65685 24 3 24ZM10.5 1.5H22.5C23.3284 1.5 24 2.17157 24 3C24 3.82843 23.3284 4.5 22.5 4.5H10.5C9.67157 4.5 9 3.82843 9 3C9 2.17157 9.67157 1.5 10.5 1.5ZM10.5 10.5H22.5C23.3284 10.5 24 11.1716 24 12C24 12.8284 23.3284 13.5 22.5 13.5H10.5C9.67157 13.5 9 12.8284 9 12C9 11.1716 9.67157 10.5 10.5 10.5ZM10.5 19.5H22.5C23.3284 19.5 24 20.1716 24 21C24 21.8284 23.3284 22.5 22.5 22.5H10.5C9.67157 22.5 9 21.8284 9 21C9 20.1716 9.67157 19.5 10.5 19.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
