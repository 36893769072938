import { ProfileQuestionReport } from '../../../interfaces/profile-question-report.interface';
import styles from './LpProfileQuestionReports.module.css';
import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';
import { LpQuestionResultChartForChoice } from '../../../components/question-result-chart/choice/LpQuestionResultChartForChoice';

interface Props {
  questionReports: ProfileQuestionReport[];
  responseCount: number;
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
    sortBy: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    profileQuestion: string;
    teamRankings: string;
    individualView: string;
    teamsView: string;
  };
}
export const LpProfileQuestionReports = ({ questionReports, text }: Props) => {
  const isQuiz = false;

  return (
    <>
      {questionReports.map(questionReport => (
        <div
          className={styles.chartRow}
          data-testid="profile-questions-report"
          key={questionReport.id}
        >
          <LpQuestionTitle
            currentQuestionNumber={questionReport.currentQuestionNumber || 0}
            title={questionReport.title}
            image={questionReport.image}
            type={text.profileQuestion}
          />
          <div>
            <LpQuestionResultChartForChoice
              isQuiz={isQuiz}
              answerStats={questionReport.answers}
              text={text}
              teams={[]}
            />
          </div>
        </div>
      ))}
    </>
  );
};
