export const SwipeQuestionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1051_32403)">
        <path
          d="M25.5479 20.3895L19.7919 17.5185C19.3978 17.3215 18.9756 17.223 18.5394 17.223H17.2587V8.77897C17.2587 7.61088 16.3158 6.66797 15.1477 6.66797C13.9796 6.66797 13.0367 7.61088 13.0367 8.77897V23.8937L8.46284 22.8523C8.23132 22.8026 7.99094 22.8126 7.7644 22.8816C7.53786 22.9505 7.33263 23.0761 7.16809 23.2464L6 24.4285L12.3893 31.1697C12.9241 31.7045 13.9937 32 14.7396 32H23.4087C24.8161 32 25.9982 30.9726 26.1953 29.5794L27.0819 23.3027C27.2508 22.1064 26.6315 20.9383 25.5479 20.3895Z"
          fill="currentColor"
        />
        <path
          d="M28.6449 4.46382C26.4052 1.81975 21.5992 0 16 0C10.4008 0 5.5948 1.81975 3.35511 4.46382L0.446625 1.55534V9.33203H8.22332L5.00377 6.11248C6.55911 4.10609 10.7585 2.33301 16 2.33301C21.2415 2.33301 25.4409 4.10609 26.9963 6.11248L23.7767 9.33203H31.5534V1.55534L28.6449 4.46382Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
