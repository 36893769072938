import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import { IMultipleChoiceQuestionReport } from '@livepolls/ui-components/src/interfaces/multiple-choice-question-report';
import { ProfileQuestionReport } from '@livepolls/ui-components/src/interfaces/profile-question-report.interface';
import { IWordCloudQuestionReport } from '@livepolls/ui-components/src/interfaces/word-cloud-report.interface';
import { TeamLeaderboardRow } from '@livepolls/ui-components/src/interfaces/team-leaderboard-row';
import { LivePollQuestionReportList } from './LivePollQuestionReportList';
import { ITeam } from '@livepolls/ui-components/src/interfaces/team.interface';

interface Props {
  livePollId: number;
  livePollSessionId: number;
  questionReports: (
    | IMultipleChoiceQuestionReport
    | ILiveFeedQuestionReport
    | IWordCloudQuestionReport
  )[];
  isQuiz: boolean;
  responseCount: number;
  profileQuestionReport?: ProfileQuestionReport[];
  showDifficultyLevels: boolean;
  enableQuestionDifficulty: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
  teams: ITeam[];
}

export const LivePollQuestionReports = ({
  livePollId,
  livePollSessionId,
  questionReports,
  isQuiz,
  responseCount,
  showDifficultyLevels,
  enableQuestionDifficulty,
  teamLeaderboardRows,
  teams,
}: Props) => {
  const difficultyLevelIndicatorStyle: React.CSSProperties = {
    fontSize: '14px',
  };

  //Note: we have removed this feature but we will this in future.
  return (
    <>
      {/* <FilterByProfileQuestionReportList
        livePollId={livePollId}
        livePollSessionId={livePollSessionId}
        isQuiz={isQuiz}
        responseCount={responseCount}
        selectedProfileQuestionId={selectedProfileQuestionId}
        questionReports={questionReports}
        answersWithColor={answersWithColor}
        showDifficultyLevels={showDifficultyLevels}
        enableQuestionDifficulty={enableQuestionDifficulty}
        difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
      /> */}

      <LivePollQuestionReportList
        livePollId={livePollId}
        livePollSessionId={livePollSessionId}
        isQuiz={isQuiz}
        responseCount={responseCount}
        questionReports={questionReports}
        showDifficultyLevels={showDifficultyLevels}
        enableQuestionDifficulty={enableQuestionDifficulty}
        teamLeaderboardRows={teamLeaderboardRows}
        difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        teams={teams}
      />
    </>
  );
};
