import { appendPositionPrefix } from '../../../utils/string.util';
import { TeamResponse } from '../../../interfaces/team-response.interface';
import { ITeam } from '../../../interfaces/team.interface';
import styles from './LpQuestionResultChartForTeamsForChoice.module.css';
import { LpPieChart } from '../../swipe-question-charts/quiz/teams/LpPieChart';
import { RibbonSvg } from '../../svgImages/Ribbon.svg';
import { SortOrder } from '../../../enums/sort-order.enum';
import { useEffect, useState } from 'react';
import { useScreenResize } from '../../../utils/getScreenSize.util';
import { isDesktopDevice } from '../../../utils/isDesktopDevice.util';

interface Props {
  barColor?: string;
  highlightBarColor?: string;
  chartColor?: string;
  responsesByTeam: TeamResponse[];
  teams: ITeam[];
  answerStats: Answer[];
  order: SortOrder;
  showDefaultChart: boolean;
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
    teamRankings: string;
  };
}

interface Answer {
  isCorrect?: boolean;
  count: number;
  id: string;
  image?: { url: string };
  text?: string;
}
interface TeamResponseCounts {
  id: string;
  totalCount: number;
  correctCount: number;
  percentage: number;
}

export const LpQuestionResultChartForTeams = ({
  teams,
  answerStats,
  responsesByTeam,
  text,
  order,
  showDefaultChart,
}: Props) => {
  const { width } = useScreenResize();
  const isDesktopView = isDesktopDevice(width);
  const [updatedTeams, setUpdatedTeams] = useState<ITeam[]>(teams);
  const responsesWithPercentage = prepareTeamsDataFromResponses(
    answerStats,
    responsesByTeam,
    teams,
  );
  const sortedResponsesByTeams = responsesWithPercentage.sort(
    (a, b) => b.percentage - a.percentage,
  );

  const maxPercentage = Math.max(
    ...responsesWithPercentage.map(team => team.percentage),
  );

  useEffect(() => {
    if (showDefaultChart) {
      if (order === SortOrder.DESC) {
        setUpdatedTeams(teams);
      }
      if (order === SortOrder.ASC) {
        const reverseTeams = [...teams].reverse();
        setUpdatedTeams(reverseTeams);
      }
    }
    if (!showDefaultChart) {
      const teamsSortedByAccuracyInDescOrder = sortedResponsesByTeams.map(
        sortedTeam => {
          return teams.find(team => team.id === sortedTeam.id)!;
        },
      );
      if (order === SortOrder.DESC) {
        setUpdatedTeams(teamsSortedByAccuracyInDescOrder);
      }
      if (order === SortOrder.ASC) {
        const reverseTeamsBySortedOrder = [
          ...teamsSortedByAccuracyInDescOrder,
        ].reverse();
        setUpdatedTeams(reverseTeamsBySortedOrder);
      }
    }
  }, [order, showDefaultChart, sortedResponsesByTeams, teams]);

  // Get all teams with the maximum percentage
  const highestPercentageTeams = responsesWithPercentage
    .filter(team => team.percentage > 0)
    .filter(team => team.percentage === maxPercentage)
    .map(team => team.id);

  const getTeamRank = (teamId: string) => {
    const index = sortedResponsesByTeams.findIndex(team => team.id === teamId);
    return index + 1;
  };

  if (!responsesByTeam) {
    return <div>{text.noDataAvailable}</div>;
  }
  const piechartWidthAndHeight = isDesktopView ? 48 : 24;
  return (
    <div
      className={styles.chartContainer}
      data-testid="questionResultChartForTeam"
    >
      <div className={styles.teamRankings}>{text.teamRankings}</div>
      <div className={styles.innerContainer}>
        {updatedTeams.map(team => {
          return (
            <div className={styles.team} key={team.id}>
              {highestPercentageTeams.includes(team.id) && (
                <span className={styles.ribbonImg}>
                  <RibbonSvg />
                </span>
              )}
              <div className={styles.teamRow}>
                <div
                  className={`${styles.teamName} ${
                    highestPercentageTeams.includes(team.id)
                      ? styles.boldTeamName
                      : ''
                  }`}
                >
                  {appendPositionPrefix(team.name, getTeamRank(team.id))}
                </div>
                <div className={styles.percentageContainer}>
                  <LpPieChart
                    width={piechartWidthAndHeight}
                    height={piechartWidthAndHeight}
                    color={team.color}
                    filledPercentage={getPercentage(
                      responsesWithPercentage,
                      team.id,
                    )}
                  />
                  <div
                    className={`${styles.percentage} ${
                      highestPercentageTeams.includes(team.id)
                        ? styles.boldPercentage
                        : ''
                    }`}
                  >
                    {getPercentage(responsesWithPercentage, team.id)}%
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getPercentage = (
  responsesWithPercentage: TeamResponseCounts[],
  teamId: string,
) => {
  return (
    responsesWithPercentage.find(res => res.id === teamId)?.percentage || 0
  );
};

const prepareTeamsDataFromResponses = (
  answerStats: Answer[],
  responsesByTeam: TeamResponse[],
  teams: ITeam[],
) => {
  const correctAnswerIds = answerStats
    .filter(option => option.isCorrect)
    .map(option => option.id);

  const countsByTeamUid: {
    [teamUid: string]: { totalCount: number; correctCount: number };
  } = responsesByTeam.reduce(
    (
      acc: { [key: string]: { totalCount: number; correctCount: number } },
      item: TeamResponse,
    ) => {
      // Initialize the entry for teamUid if not present
      if (!acc[item.teamUid]) {
        acc[item.teamUid] = { totalCount: 0, correctCount: 0 };
      }

      // Increment the total count
      acc[item.teamUid].totalCount += item.count;

      // Increment the correct count if the answerId is in the correctAnswerIds
      if (correctAnswerIds.includes(item.answerId)) {
        acc[item.teamUid].correctCount += item.count;
      }

      return acc;
    },
    {},
  );

  const result: TeamResponseCounts[] = Object.entries(countsByTeamUid).map(
    ([id, counts]) => ({
      id,
      totalCount: counts.totalCount,
      correctCount: counts.correctCount,
      percentage:
        counts.totalCount > 0
          ? Math.floor((counts.correctCount / counts.totalCount) * 100)
          : 0,
    }),
  );

  const res = addResponsesIfResponseForTeamIsNotPresent(result, teams);
  return res;
};

const addResponsesIfResponseForTeamIsNotPresent = (
  result: TeamResponseCounts[],
  teams: ITeam[],
) => {
  teams.forEach(team => {
    const teamExists = result.some(response => response.id === team.id);
    if (!teamExists) {
      result.push({
        id: team.id,
        totalCount: 0,
        correctCount: 0,
        percentage: 0,
      });
    }
  });
  return result;
};
