import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';
import { ISwipeQuestionReport } from 'src/interfaces/swipe-question-report.interface';
import { LpSwipeQuestionFinishedScreen } from '../../../components/swipe-question-finished/LpSwipeQuestionFinishedScreen';
import { TeamLeaderboardRow } from 'src/interfaces/team-leaderboard-row';
import { SwipeQuestionIcon } from '../../../components/svgImages/SwipeQuestionIcon.svg';
import { LpTopThreeLeaderboard } from './LpTopThreeLeaderboard';
import styles from './LpSwipeQuestionReport.module.css';

interface Props {
  questionReport: ISwipeQuestionReport;
  text: {
    initializing: string;
    noDataAvailable: string;
    sortBy: string;
    default: string;
    accuracy: string;
    swipeQuestionCorrectText: string;
    swipeQuestionIncorrectText: string;
    teamRankings: string;
    questionTypeText: string;
    topScorers: string;
    individualView: string;
    teamsView: string;
  };
  isQuiz: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
}

export const LpSwipeQuestionReport = ({
  questionReport,
  text,
  isQuiz,
  teamLeaderboardRows,
}: Props) => {
  const isTeamsEnabled =
    questionReport.swipeQuestionResponsesByTeam != null &&
    teamLeaderboardRows != null &&
    teamLeaderboardRows.length > 0;

  const topThreeLeaderboardArray =
    questionReport.leaderboard?.slice(0, 3).map(item => {
      return { name: item.respondentName, value: item.score.toString() };
    }) || [];

  return (
    <div className={styles.container}>
      <LpQuestionTitle
        currentQuestionNumber={questionReport.currentQuestionNumber || 0}
        title={questionReport.title || 'Untitled'}
        type={text.questionTypeText}
        icon={<SwipeQuestionIcon />}
      />
      <div>
        {isQuiz && (
          <LpTopThreeLeaderboard
            title={text.topScorers}
            items={topThreeLeaderboardArray}
            testIdForName="topItemForSwipeQuestion"
            testIdForScore="topItemScoreForSwipeQuestion"
          />
        )}
      </div>
      <div className={styles.innerContainer} data-testid="swipeQuestionReport">
        <LpSwipeQuestionFinishedScreen
          isQuiz={isQuiz}
          swipeQuestion={{
            swipeOptions: (questionReport as ISwipeQuestionReport).swipeOptions,
            cards: (questionReport as ISwipeQuestionReport).cards,
          }}
          text={text}
          questionResult={questionReport as ISwipeQuestionReport}
          isTeamsEnabled={isTeamsEnabled}
          isReportScreen={true}
          teams={createTeamsFromTeamLeaderboardRows(teamLeaderboardRows || [])}
        />
      </div>
    </div>
  );
};

const createTeamsFromTeamLeaderboardRows = (
  teamLeaderboardRows: TeamLeaderboardRow[],
) => {
  return teamLeaderboardRows.map(teamLeaderboardRow => ({
    id: teamLeaderboardRow.teamUid,
    name: teamLeaderboardRow.teamName,
    color: teamLeaderboardRow.teamColor,
  }));
};
