import { LpLeaderboard } from '@livepolls/ui-components/src/components/leaderboard/LpLeaderboard';
import { LpProfileQuestionReports } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpProfileQuestionReports';
import { LpPanelContainer } from '@livepolls/ui-components/src/components/panel/LpPanelContainer';
import { LpPanelHeading } from '@livepolls/ui-components/src/components/panel/LpPanelHeading';
import { DashboardPanelType } from '@livepolls/ui-components/src/enums/dashboard-panel-type.enum';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import {
  transformLeaderboardToPager,
  transformTeamLeaderboardToPager,
} from '@livepolls/ui-components/src/utils/generate-leaderboard-row';
import { useState } from 'react';
import styles from './DashboardReportsForMobile.module.css';
import { LivePollQuestionReports } from './LivePollQuestionReports';
import { LpCopyReportUrl } from '@livepolls/ui-components/src/components/copy-report-url/LpCopyReportUrl';
import { ITeam } from '@livepolls/ui-components/src/interfaces/team.interface';

interface Props {
  livePollSession: ILivePollSession;
  livePoll: { id: number; type: LivePollType };
  livePollSessionReport: ILivePollSessionReport;
  teams: ITeam[];
  reportUrl?: string;
}
export const DashboardReportsForMobile = ({
  livePollSession,
  livePoll,
  livePollSessionReport,
  reportUrl,
  teams,
}: Props) => {
  const [panelType, setPanelType] = useState<DashboardPanelType>(
    DashboardPanelType.LIVEPOLL_QUESTIONS,
  );
  const isProfileQuestionsActive =
    panelType === DashboardPanelType.PROFILE_QUESTIONS;

  const isLivePollQuestionsActive =
    panelType === DashboardPanelType.LIVEPOLL_QUESTIONS;

  const isLeaderboardActive = panelType === DashboardPanelType.LEADERBOARD;

  const isQuiz = livePoll.type === LivePollType.QUIZ;

  const {
    responseCount,
    questionReports,
    leaderboardRows,
    profileQuestionReports,
    teamLeaderboardRows,
  } = livePollSessionReport;

  const showDifficultyLevels =
    !!livePollSession.setting?.displayDifficultyLevels;
  const enableQuestionDifficulty =
    !!livePollSession.setting?.enableQuestionDifficulty;
  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;

  const handlePanelChange = (panelType: DashboardPanelType) => {
    setPanelType(panelType);
  };

  return (
    <>
      <div className={styles.left}>
        <div className={styles.leftWrapper}>
          <div className={styles.questionsContainer}>
            <div className={styles.sessionName}>
              {livePollSession.name || 'Untitled'}
              <div className={styles.copyLink}>
                <LpCopyReportUrl reportUrl={reportUrl} />
              </div>
            </div>

            <LpPanelContainer customContainerClass={styles.panelContainer}>
              <LpPanelHeading
                onPanelHeadClick={() =>
                  handlePanelChange(DashboardPanelType.LIVEPOLL_QUESTIONS)
                }
                isActive={isLivePollQuestionsActive}
              >
                <span className={styles.panelText}>Response summary</span>
              </LpPanelHeading>
              {livePollSession.setting?.enableProfileQuestions && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handlePanelChange(DashboardPanelType.PROFILE_QUESTIONS)
                  }
                  isActive={isProfileQuestionsActive}
                >
                  <span className={styles.panelText}>Profile Questions</span>
                </LpPanelHeading>
              )}

              {isQuiz && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handlePanelChange(DashboardPanelType.LEADERBOARD)
                  }
                  isActive={isLeaderboardActive}
                >
                  <span className={styles.panelText}>Leaderboard</span>
                </LpPanelHeading>
              )}
            </LpPanelContainer>
            {isLivePollQuestionsActive && (
              <LivePollQuestionReports
                livePollId={livePollSession.livePollId}
                livePollSessionId={livePollSession.id}
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReports={questionReports}
                profileQuestionReport={profileQuestionReports}
                showDifficultyLevels={showDifficultyLevels}
                enableQuestionDifficulty={enableQuestionDifficulty}
                teamLeaderboardRows={teamLeaderboardRows}
                teams={teams}
              />
            )}
            {isProfileQuestionsActive && (
              <LpProfileQuestionReports
                questionReports={profileQuestionReports!}
                responseCount={responseCount}
                text={{
                  noVotes: 'No votes',
                  outOf: 'out of',
                  votes: 'votes',
                  initializing: 'Initializing',
                  noDataAvailable: 'No Data Available',
                  sortBy: 'Sort by',
                  default: 'Default',
                  popularity: 'Popularity',
                  accuracy: 'Accuracy',
                  reverseOrder: 'Reverse order',
                  profileQuestion: 'Profile question',
                  teamRankings: 'Teams ranking',
                  individualView: 'Individual view',
                  teamsView: 'Teams view',
                }}
              />
            )}
            {isLeaderboardActive && (
              <div className={styles.dashboardReport}>
                <LpLeaderboard
                  showSequenceNum={true}
                  pagewiseResults={transformLeaderboardToPager(
                    leaderboardRows,
                    {
                      leaderboard: 'Leaderboard',
                    },
                  )}
                  text={{ noDataAvailable: 'No Data Available' }}
                  teamResults={transformTeamLeaderboardToPager(
                    teamLeaderboardRows || [],
                  )}
                  isTeamsEnabled={isTeamsEnabled}
                  isResultScreeen={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
