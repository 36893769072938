import { SortOrder } from '../../../enums/sort-order.enum';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';
import ascendingSvg from '../../../images/ascending.svg';
import descendingSvg from '../../../images/descending.svg';
import { LpDropdown } from '../../dropdown/LpDropdown';
import { LpImageToogle } from '../../image-toggle/LpImageToggle';
import { ToggleSwitchSideEnum } from '../../../enums/toggle-switch-side.enum';
import userGroupWhite from '../../../images/user-group-white.svg';
import userGroup from '../../../images/user-group.svg';
import { UserIconSvg } from '../../svgImages/UserIcon.svg';

import styles from './LpChoiceQuestionResultChartHeader.module.css';

interface Props {
  isQuiz: boolean;
  order: SortOrder;
  view: ToggleSwitchSideEnum;
  isTeamsEnabled: boolean;
  onChangeView: (view: ToggleSwitchSideEnum) => void;
  onChangeOrder: (order: SortOrder) => void;
  onDropDownChange: (isDefault: boolean) => void;
  dropownText: string;
  text: {
    sortBy: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    individualView: string;
    teamsView: string;
  };
}

export const LpChoiceQuestionResultChartHeader = ({
  isQuiz,
  order,
  dropownText,
  text,
  view,
  onChangeOrder,
  onDropDownChange,
  onChangeView,
  isTeamsEnabled,
}: Props) => {
  const QUIZ_OPTIONS = [
    <li
      className={styles.dropDownItem}
      onClick={() => onDropDownChange(true)}
      key="default"
    >
      {text.default}
    </li>,
    <li
      className={styles.dropDownItem}
      onClick={() => onDropDownChange(false)}
      key="accuracy"
    >
      {text.accuracy}
    </li>,
  ];

  const POLL_OPTIONS = [
    <li
      className={styles.dropDownItem}
      onClick={() => onDropDownChange(true)}
      key="default"
    >
      {text.default}
    </li>,
    <li
      className={styles.dropDownItem}
      onClick={() => onDropDownChange(false)}
      key="popularity"
    >
      {text.popularity}
    </li>,
  ];

  return (
    <div className={styles.container}>
      {isTeamsEnabled && (
        <div className={styles.leftSideSwitcher}>
          <LpImageToogle
            onChangeView={onChangeView}
            activeView={view}
            leftTooltipText={text.individualView}
            rightTooltipText={text.teamsView}
            leftSideIcon={
              <span style={{ color: '#545E6B' }}>
                <UserIconSvg />
              </span>
            }
            leftSideActiveIcon={
              <span style={{ color: '#fff' }}>
                <UserIconSvg />
              </span>
            }
            rightSideIcon={<img src={userGroup} alt="inactive teams view" />}
            rightSideActiveIcon={<img src={userGroupWhite} alt="teams view" />}
          />
        </div>
      )}
      <div
        className={`${styles.leftSideContainer} ${
          isTeamsEnabled
            ? styles.leftSideContainerFlexStart
            : styles.leftSideContainerSpaceBetween
        }`}
      >
        <div className={styles.sortByLeftSide}>
          <div>{text.sortBy}</div>
          <LpDropdown
            text={dropownText}
            customDropdownBtnClass={styles.dropDown}
            customDropdownContentClass={styles.dropDownContent}
            customDropdownTextClass={styles.dropdownText}
          >
            <ul>{isQuiz ? QUIZ_OPTIONS : POLL_OPTIONS}</ul>
          </LpDropdown>
        </div>
        <div>
          <WuTooltip content={text.reverseOrder} position="bottom">
            <div className={styles.ascOrDescIcon}>
              <img
                src={order === SortOrder.DESC ? descendingSvg : ascendingSvg}
                onClick={() => onChangeOrder(order)}
                alt="change answer order"
                className={styles.icon}
                height={16}
              />
            </div>
          </WuTooltip>
        </div>
      </div>
    </div>
  );
};
