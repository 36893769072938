import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import styles from './LiveFeedReport.module.css';
import { LpQuestionTitle } from '@livepolls/ui-components/src/components/question-title/LpQuestionTitle';
import { LpLiveFeedStats } from '@livepolls/ui-components/src/components/live-feed-question-report/LpLiveFeedStats';
import { useEffect, useState } from 'react';
import { ILiveFeedQuestionResultPaginationParams } from '@livepolls/ui-components/src/interfaces/live-feed-question-result-pagination-params.interface';
import { LiveFeedStatsDropdownEnum } from '@livepolls/ui-components/src/enums/live-feed-stats-dropdown.enum';
import {
  LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
  LIVE_FEED_PAGINATION_START_PAGE_INDEX,
} from '@livepolls/ui-components/src/constants/live-feed-pagination.constants';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { getApi } from 'src/utils/api.util';
import { LiveFeedQuestionResponse } from '@livepolls/ui-components/src/interfaces/livefeed-question-response';
interface Props {
  livePollId: number;
  livePollSessionId: number;
  liveFeedQuestionReport: ILiveFeedQuestionReport;
}

interface LiveFeedQuestionResult {
  liveFeedResponses: LiveFeedQuestionResponse[];
  responseCount: number;
  responseCountForLiveFeedView: number;
}

const getFetchLiveFeedCommentsUrl = (
  livePollSessionId: number,
  questionId: string,
): string => {
  return `/take/sharable-report/livepoll-session/${livePollSessionId}/question-result/${questionId}`;
};

export const LiveFeedReport = ({
  livePollId,
  livePollSessionId,
  liveFeedQuestionReport,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [liveFeedQuestionResult, setLiveFeedQuestionResult] =
    useState<LiveFeedQuestionResult | null>(null);

  const [paginationParams, setPaginationParams] =
    useState<ILiveFeedQuestionResultPaginationParams>({
      liveFeedView: LiveFeedStatsDropdownEnum.LIVE_FEED,
      pageIndex: LIVE_FEED_PAGINATION_START_PAGE_INDEX,
      perPage: LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
    });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const liveFeedQuestionResult = await getApi(
          getFetchLiveFeedCommentsUrl(
            livePollSessionId,
            liveFeedQuestionReport.id,
          ),
          {
            params: {
              liveFeedView: paginationParams.liveFeedView,
              pageIndex: paginationParams.pageIndex,
              perPage: paginationParams.perPage,
            },
          },
        );
        setLiveFeedQuestionResult(liveFeedQuestionResult);
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    liveFeedQuestionReport.id,
    livePollId,
    livePollSessionId,
    paginationParams.liveFeedView,
    paginationParams.pageIndex,
    paginationParams.perPage,
  ]);

  if (error) {
    return <div>Oops an error occurred (${error?.message}).</div>;
  }

  const handleDropDownChange = (dropDownText: LiveFeedStatsDropdownEnum) => {
    setPaginationParams({
      liveFeedView: dropDownText,
      pageIndex: LIVE_FEED_PAGINATION_START_PAGE_INDEX,
      perPage: LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
    });
  };

  const handlePageChange = (pager: IPaginateDropdown) => {
    setPaginationParams(current => {
      return { ...current, ...pager };
    });
  };

  const totalItemsForPagination =
    liveFeedQuestionResult?.responseCountForLiveFeedView || 0;

  const liveFeeQuestionResponses =
    liveFeedQuestionResult?.liveFeedResponses || [];

  return (
    <div className={styles.container}>
      <LpQuestionTitle
        currentQuestionNumber={
          liveFeedQuestionReport.currentQuestionNumber || 0
        }
        title={liveFeedQuestionReport.title || ''}
        type="Livefeed"
      />

      <div className={styles.liveFeedStatsContainer}>
        <LpLiveFeedStats
          isLoading={isLoading}
          dropDownText={paginationParams.liveFeedView}
          onDropDownChange={handleDropDownChange}
          liveFeedQuestionResponses={liveFeeQuestionResponses}
          totalItems={totalItemsForPagination}
          pager={{
            pageIndex: paginationParams.pageIndex,
            perPage: paginationParams.perPage,
          }}
          onPageChange={handlePageChange}
          resultScreen={true}
          text={{
            liveFeed: 'Live Feed',
            topVoted: 'Top Voted',
            bookmarked: 'Bookmarked',
            comments: 'Comments',
            showing: 'Showing',
            showUpTo: 'Show up to',
            of: 'of',
            to: 'to',
            invalidDropdownError: 'Invalid Live Feed drop down text',
            noComments: 'No Comments',
            noVotesForComments: 'No votes for comments',
            noBookmarkedComments: 'No bookmarked comments',
            submissionTime: 'Submission time',
            name: 'name',
            favorites: 'Favorites',
            votes: 'Votes',
          }}
        />
      </div>
    </div>
  );
};
