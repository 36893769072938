export const UserIconSvg = () => {
  const image = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02079 0.0415039C5.81738 0.0415039 4.03118 1.82771 4.03118 4.03111C4.03118 6.23452 5.81738 8.02072 8.02079 8.02072C10.2242 8.02072 12.0104 6.23452 12.0104 4.03111C12.0104 1.82771 10.2242 0.0415039 8.02079 0.0415039Z"
        fill="currentColor"
      />
      <path
        d="M6.69092 9.35059C3.01858 9.35059 0.0415649 12.3276 0.0415649 15.9999H16C16 12.3276 13.023 9.35059 9.35066 9.35059H6.69092Z"
        fill="currentColor"
      />
    </svg>
  );
  return image;
};
